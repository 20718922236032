p-calendar .p-inputtext:enabled:hover {
    color: var(--bot-text);
}

.p-inputtext {
    border: none;
    &:enabled {
        &:hover {
            border-color: var(--user-background);
        }
        &:focus {
            border-color: var(--bot-text);
            box-shadow: none;
        }
    }
}

.p-datepicker {
    table {
        td {
            & > span {
                &.p-highlight {
                    background: var(--button-background);
                    color: var(--user-text);
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
    .p-datepicker-header {
        .p-datepicker-title {
            .p-datepicker-month {
                &:enabled {
                    &:hover {
                        color: var(--bot-text);
                    }
                }
            }
            .p-datepicker-year {
                &:enabled {
                    &:hover {
                        color: var(--bot-text);
                    }
                }
            }
        }
        .p-datepicker-prev {
            &:focus {
                box-shadow: 0 0 0 0.2rem #5b8d97;
            }
        }
        .p-datepicker-next {
            &:focus {
                box-shadow: 0 0 0 0.2rem #5b8d97;
            }
        }
    }
    .p-monthpicker {
        .p-monthpicker-month {
            &.p-highlight {
                background: none;
            }
        }
    }
    .p-yearpicker {
        .p-yearpicker-year {
            &.p-highlight {
                background: var(--button-background);
                color: var(--user-text);
            }
        }
    }
    &:not(.p-disabled) {
        .p-yearpicker {
            .p-yearpicker-year {
                &:not(.p-disabled):focus {
                    box-shadow: 0 0 0 0.2rem #5b8d97;
                }
                &:not(.p-disabled):not(.p-highlight):hover {
                    background: none;
                }
            }
        }
        .p-monthpicker {
            .p-monthpicker-month {
                &:not(.p-disabled):focus {
                    box-shadow: 0 0 0 0.2rem #5b8d97;
                }
                &:not(.p-disabled):not(.p-highlight):hover {
                    background: none;
                }
            }
        }
    }
}

.p-calendar-w-btn .p-datepicker-trigger {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

app-form-area {
    p-calendar {
        width: 100%;

        span {
            width: 100%;
        }

        .p-button {
            background: var(--user-text);
            border-color: var(--user-text);
            color: var(--bot-text);

            .pi {
                font-size: 1.2rem;
            }

            &:focus {
                box-shadow: none;
                background: var(--user-text);
                color: var(--bot-text);
            }

            &:enabled {
                &:hover {
                    background: var(--user-text);
                    border-color: var(--user-text);
                    color: var(--bot-text);
                }
            }
        }
    }
}
