:root {
  --primary: #06323b;
  --secondary: #ffffff;
  --header-background: #06323b;
  --bot-background: #ffffff;
  --bot-text: #06323b;
  --user-background: #06323b;
  --hoverColor: #274e56;
  --user-text: #ffffff;
  --button-background: #1bc4b1;
  --button-text: #ffffff;
  --complete-step-color: #38c976;
  --skip-step-color: #fe5050;
  --background: #06323b;
  --model-background: #e5eaeb;
  --in-progress: #ff9700;
}