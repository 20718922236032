$whiteColor: white;
$blackColor: black;
$greenColor: green;
$redColor: red;
$checklistDocBlueDot: #2b6dff;
$checklistDocOrangeDot: #f48f01;
$grayColor: gray;
$darkGrayColor: #555B62;
$darkGrayColor: #767171;

$borderRadius: 10px;

$stepHighlightColor: #2f4f4f90;
