@import "node_modules/bootstrap/dist/css/bootstrap.min.css" layer(primeng);

@layer primeng {
    @import "src/app/theme/theme-variables.scss";
    @import "src/assets/css/primeng-override/calendar";

    html,
    body {
        height: 100vh !important;
        width: 100% !important;
    }

    body {
        line-height: 1.25rem !important;
        font-size: 14px;
        margin: 0;
        font-family: Roboto, "Helvetica Neue", sans-serif;
    }

    //root color panel | end

    .primary__color {
        color: var(--primary);
    }

    .primary__background {
        background-color: var(--primary) !important;
        color: #ffffff !important;
        border: 0;
        padding: 7px 15px;
        border-radius: 5px;
    }

    .secondary__color {
        color: var(--secondary);
    }

    .secondary__background {
        color: var(--secondary);
    }

    .header__color {
        color: var(--header-background);
    }

    .header__background {
        background-color: var(--header-background);
        border-radius: 8px 8px 0 0;
    }

    .bot__color {
        color: var(--bot-text);
    }

    .bot__background {
        background-color: var(--bot-background);
    }

    .bot__background-color {
        color: var(--bot-background);
    }

    .user__color {
        color: var(--user-text);
    }

    .user__background {
        background-color: var(--user-background);
    }

    .user__background-color {
        color: var(--user-background);
    }

    .button__color {
        color: var(--button-text);
    }

    .button__background {
        background-color: var(--button-background);
    }

    .button__background-color {
        color: var(--button-background);
    }

    .complete__step-color {
        color: var(--complete-step-color);
    }

    .complete__step-backgroundColor {
        background: var(--complete-step-color);
    }

    .skip__step-color {
        color: var(--skip-step-color);
    }

    .skip__step-backgroundColor {
        background: var(--skip-step-color);
    }

    .in-progress__color {
        color: var(--in-progress);
    }

    .in-progress__backgroundColor {
        background: var(--in-progress);
    }

    .model-background__color {
        color: var(--model-background);
    }

    .model-background__backgroundColor {
        background: var(--model-background);
        height: calc(100% - 140px);
    }

    .green__color {
        color: $greenColor;
    }

    .red__color {
        color: $redColor;
    }

    .green__background {
        background-color: $greenColor;
    }

    .red__background {
        background-color: $redColor;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .border__radius {
        border-radius: 10px;
    }

    .infoText {
        height: 100vh;
        background-color: var(--header-background);
        div {
            font-size: 30px;
            color: $whiteColor;
        }
        span {
            font-size: 20px;
            width: 50vw;
            margin: 20px;
            line-height: 1.2;
            color: $whiteColor;
        }
    }

    .flex-1 {
        flex: 1 !important;
    }

    .flex-2 {
        flex: 2 !important;
    }

    .flex-3 {
        flex: 3 !important;
    }

    .p-40 {
        padding: 40px !important;
    }

    .f-14 {
        font-size: 14px !important;
    }

    .f-16 {
        font-size: 16px !important;
    }

    .f-18 {
        font-size: 18px !important;
    }

    .f-20 {
        font-size: 20px !important;
    }

    .f-30 {
        font-size: 30px !important;
    }

    .f-40 {
        font-size: 40px !important;
    }

    .f-60 {
        font-size: 60px !important;
    }

    .w-80 {
        width: 80% !important;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .mt-30 {
        margin-top: 30px;
    }

    .mt-40 {
        margin-top: 40px;
    }

    .ml-auto {
        margin-left: auto;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .ml-40 {
        margin-left: 40px !important;
    }

    .custom_phone_dropdown {
        .p-dropdown-items-wrapper {
            height: 200px !important;
            width: 340px !important;
        }
    }

    .custom_country_dropdown {
        .p-dropdown-items-wrapper {
            height: 200px !important;
        }
    }

    .custom__back {
        background: var(--background) 0% 0% no-repeat padding-box;
        border-radius: 10px;
        height: calc(100% - 80px);
    }

    .saveAndNext {
        background: var(--button-background);
        color: var(--button-text);
        outline: 0;
        border: 1px solid white;
        padding: 10px 15px;
        border-radius: 10px;
        font-size: 18px;
        cursor: pointer;
    }

    .custom__btn {
        @extend .center;
        padding: 15px;
        background: var(--button-background);
        color: var(--button-text);
        @extend .border__radius;
        outline: 0 !important;
        border: 0 !important;
    }

    //ToDo: add button hover effect in future
    .custom__btn:hover {
        background: var(--button-background);
        color: var(--button-text);
    }

    .not-allowed {
        opacity: 0.3 !important;
        cursor: not-allowed !important;
    }

    .c-p {
        cursor: pointer;
    }

    .opacity-5 {
        opacity: 0.5;
    }

    //loop | start

    .border__custom {
        border: 0.25px solid var(--user-background);
        border-radius: 5px;
        padding: 0 10px;
    }

    .custom__input {
        outline: none;
        border-radius: 5px;
        padding: 10px 10px 10px 0;
        border: 0;
        color: var(--user-background);
    }

    .custom__color {
        color: var(--button-background);
    }

    .custom__textarea {
        padding: 10px 15px 10px 0;
        resize: none;
        max-height: 40px;
        min-height: 40px;
        border: 0;
        outline: none;
    }

    .pt-10 {
        padding-top: 10px;
    }

    .custom__btn-new {
        border-radius: 5px;
        padding: 10px 20px;
        height: fit-content !important;
        background: var(--user-background);
        color: var(--user-text);
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 0 !important;
        border: 0 !important;
    }

    .custom__btn-new:hover {
        background: var(--button-background);
        color: var(--button-text);
    }

    .d-none,
    .column__1,
    .column__3 {
        display: none !important;
    }

    .column__2 {
        @extend .flex-3;
    }

    .outline-0 {
        outline: 0;
    }

    .header__image {
        width: 250px !important;
        height: 70px !important;
    }

    .isSelected {
        background: var(--button-background);
        color: var(--button-text);
    }

    .not-allow {
        opacity: 0.5;
        cursor: no-drop;
    }

    .wordWrap {
        word-break: break-word;
    }

    .mt-10 {
        margin-top: 10px;
    }

    .mr-10 {
        margin-right: 10px;
    }

    .mr-20 {
        margin-right: 20px;
    }

    .f-22 {
        font-size: 22px;
    }

    //CUSTOM SCROLLBAR | START
    ::-webkit-scrollbar {
        border-radius: 10px;
        width: 6px;
        background-color: #f5f5f5;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
    }

    //CUSTOM SCROLLBAR | END
    /*
    ##Device = Desktops
    ##Screen = 1281px to higher resolution desktops
    */

    @media (min-width: 1281px) {
        .column__1 {
            flex: 1;
            display: block !important;
        }
        .column__3 {
            display: block !important;
        }
        .desktop__padding-40 {
            padding: 40px !important;
        }

        .custom__height {
            height: calc(100vh - 80px) !important;
        }
    }

    /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
    */

    @media (min-width: 1025px) and (max-width: 1280px) {
        .column__1 {
            flex: 1;
            display: block !important;
        }
        .column__3 {
            display: block !important;
        }

        .desktop__padding-40 {
            padding: 40px !important;
        }

        .custom__height {
            height: calc(100vh - 80px) !important;
        }
    }

    /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) {
        /* CSS */
    }

    /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
    */

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        /* CSS */
    }

    /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    */

    @media (min-width: 481px) and (max-width: 767px) {
        /* CSS */
    }

    @media screen and (max-width: 462px) {
        .custom_phone_dropdown {
            .p-dropdown-items-wrapper {
                width: 250px !important;
            }
        }
    }

    /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
    */

    @media (min-width: 320px) and (max-width: 480px) {
        /* CSS */
    }

    .br-score {
        .br-units {
            align-items: center;
        }

        .br-unit {
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("../src/assets/images/star.svg") !important;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-left: 5px;
            width: 28px;
            height: 28px;
            filter: grayscale(1);
        }

        .br-selected,
        .br-active {
            filter: grayscale(0);
        }
    }

    .p-3px {
        padding: 3px !important;
    }

    .flex{
        display: flex;
    }
}